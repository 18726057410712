.css-12blrsj-MuiListItem-root {
  /* background-color: aquamarine; */
  /* color: black !important; */
}

/* sidnav fade when modal is shown */
.css-lq19x5-MuiDrawer-docked .MuiDrawer-paper {
  top: 0px;
  z-index: 100;
  background-color: #ffffff !important;
  color: #000 !important;
  margin-top: 93px !important;
}
.css-9ny6va .MuiDrawer-paper {
  margin-top: 93px !important;
}

.css-9ny6va .MuiDrawer-paper {
  top: 0px;
  z-index: 100;
  background-color: #333545 !important;
  color: #ffffff !important;
}

.css-ef811l-MuiListItemIcon-root {
  background: #ffffff;
}

@media (min-width: 1200px) {
  .css-ef811l-MuiListItemIcon-root {
    background: #ffffff;
  }
}

@media (max-width: 1200px) {
  .css-lq19x5-MuiDrawer-docked .MuiDrawer-paper {
    top: 0px;
    z-index: 10000;
  }

  .css-9ny6va .MuiDrawer-paper {
    top: 100px;
    z-index: 10000;
  }

  .css-1aut13v-MuiPaper-root-MuiCard-root {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (min-width: 1200px) {
  .css-rufnm2-MuiListItemIcon-root {
    background: #1a77d1 !important;
  }
}

/* .css-ef811l-MuiListItemIcon-root{
    background: none;
} */

.css-lemao1-MuiListItemText-root {
  color: #737791 !important;
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.css-tsm3pj-MuiTypography-root {
  color: rgb(225, 216, 216) !important;
}

.activeBar {
  background-color: rgba(59, 80, 146, 1);
}
.activeBarText {
  color: white !important;
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.is-blurred {
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.css-1clhifh {
  color: #737791 !important;
}
.css-12blrsj-MuiListItem-root {
  padding-top: 15px !important;
}
.css-1jmpj1x {
  color: #ffffff !important ;
  background: #1a77d1 !important;
  border-radius: 16px !important;
}
.css-ef811l-MuiListItemIcon-root svg {
  fill: #000000 !important;
}
.css-ef811l-MuiListItemIcon-root {
  background: #1a77d1 !important;
  box-shadow: none !important;
}
.css-1vpynlb {
  padding-top: 15px !important;
}
.css-pys03g {
  background: #a0a3b9 !important;
}
.css-pys03g svg {
  fill: #212529 !important;
}
.css-kojta8-MuiPaper-root-MuiAppBar-root {
  background-color: #ffffff !important;
}
